<template>
  <base-list :items="items" />
</template>

<script>
import {
  genAddItem,
  genDefaultItem,
  genMenuItemUnlink
} from '@/utils/list-generators';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';
import { useLandmarkMenu } from '@/modules/landmarks/compositions/landmarkMenu';
import { useGeozoneMenu } from '@/modules/geozones/compositions/geozoneMenu';
import { computed, inject, ref } from 'vue-demi';
import { objectGeoService, objectService } from '@/modules/objects/api';
import { prepareObject } from '@/provider/utils';
import { colors } from '@/compositions/map/utils/data';
import { usePopup } from '@/compositions/popup';
import { onActivated } from '@vue/composition-api';

export default {
  name: 'ObjectCardGeo',
  props: {
    onlyGeotags: {
      type: Boolean,
      default: false
    },
    injectObjectKey: {
      type: String,
      default: 'object'
    }
  },
  setup(props) {
    const object = inject(props.injectObjectKey);
    const geozones = ref([]);
    const landmarks = ref([]);
    const geotags = ref([]);
    const { getNextRoute } = useHistoryStack();
    const popup = usePopup();

    const fetchGeoObjects = async () => {
      if (!props.onlyGeotags) {
        geozones.value = (
          await objectGeoService.fetchGezones(object.value.id)
        ).map(geozone => prepareObject(geozone));
        landmarks.value = (
          await objectGeoService.fetchLandmarks(object.value.id)
        ).map(landmark => prepareObject(landmark));
      }
      geotags.value = (
        await objectGeoService.fetchGeotags(object.value.id)
      ).map(geotag => prepareObject(geotag));
    };

    const genLandmarkActions = landmark => [
      ...useLandmarkMenu({ entity: landmark }),
      genMenuItemUnlink(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Unlink landmark?',
            onConfirm: async () => {
              await objectService.unlink(landmark.relationId);
              await fetchGeoObjects();
            }
          }
        });
      })
    ];

    const genGeozoneActions = geozone => [
      ...useGeozoneMenu({ entity: geozone }),
      genMenuItemUnlink(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Unlink geozone?',
            onConfirm: async () => {
              await objectService.unlink(geozone.relationId);
              await fetchGeoObjects();
            }
          }
        });
      })
    ];

    const genGeotagActions = geotag => [
      genMenuItemUnlink(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Unlink geotag?',
            onConfirm: async () => {
              await objectService.unlink(geotag.relationId);
              await fetchGeoObjects();
            }
          }
        });
      })
    ];

    const genGeozoneItem = geozone => {
      return genDefaultItem({
        title: geozone.name,
        subTitle: '',
        icon: '$geozone',
        avatarColor: colors[geozone.infoColor?.value || 'default'],
        actions: genGeozoneActions(geozone),
        to: getNextRoute({
          component: componentAliases.gc,
          props: {
            geozoneId: geozone.id
          }
        })
      });
    };

    const genLandmarkItem = landmark => {
      return genDefaultItem({
        title: landmark.name,
        subTitle: '',
        icon: '$landmark',
        avatarColor: colors[landmark.infoColor?.value || 'default'],
        actions: genLandmarkActions(landmark),
        to: getNextRoute({
          component: componentAliases.lc,
          props: {
            landmarkId: landmark.id
          }
        })
      });
    };

    const genGeotagItem = geotag => {
      return genDefaultItem({
        title: geotag.name,
        subTitle: '',
        icon: '$geotag',
        avatarColor: colors['default'],
        actions: genGeotagActions(geotag),
        to: getNextRoute({
          component: componentAliases.gt,
          props: {
            geotagId: geotag.id
          }
        })
      });
    };

    const addGeoObject = async () => {
      popup.open({
        component: () => import('./GeoObjectAdd.vue'),
        props: {
          objectId: object.value.id,
          onlyGeotags: props.onlyGeotags
        },
        listeners: {
          confirm: () => fetchGeoObjects()
        }
      });
    };

    const items = computed(() => [
      ...(!props.onlyGeotags ? geozones.value.map(i => genGeozoneItem(i)) : []),
      ...(!props.onlyGeotags
        ? landmarks.value.map(i => genLandmarkItem(i))
        : []),
      ...geotags.value.map(i => genGeotagItem(i)),
      genAddItem(
        { title: 'Add' },
        {
          click: addGeoObject
        }
      )
    ]);

    onActivated(async () => {
      fetchGeoObjects();
    });

    return { items };
  }
};
</script>

<style></style>
